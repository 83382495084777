import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

interface Props {
  image: any
  alt: string
}

export const GatsbyImageSVGFallback = ({ image, alt }: Props) => {
  if (image?.asset?.localFile?.childImageSharp) {
    return <GatsbyImage alt={alt ? alt : ''} backgroundColor="#fff" image={image.asset.localFile.childImageSharp.gatsbyImageData} />
  } else if (image.asset.gatsbyImageData) {
    return <GatsbyImage alt={alt ? alt : ''} backgroundColor="#fff" image={image.asset.gatsbyImageData} />
  }else if (image?.asset?.localFile?.publicURL) {
    return <img src={image.asset.localFile.publicURL} alt={alt ? alt : ''} />
  } else {
    return <img />
  }
}
