import React from 'react'
import { graphql, Link } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import { GatsbyImage } from 'gatsby-plugin-image'
import PortableText from '../lib/portableText'
import { Seo } from '../components/seo'
import randomFromArray from '../lib/random-from-array'
import { GatsbyImageSVGFallback } from '../components/gatsby-image-svg-fallback'
import { DateTime } from 'luxon'
import { getLocalizedLink } from '../lib/helpers'
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript'

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      _updatedAt
      _createdAt
      tags {
        en {
          value
          label
        }
        fi {
          value
          label
        }
        et {
          value
          label
        }
      }
      slug {
        en {
          current
        }
        fi {
          current
        }
      }
      image {
        altText {
          en
          et
          fi
        }
        asset {
          _id
          gatsbyImageData
          localFile {
            id
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      _rawBody
      _rawTags
      title {
        en
        et
        fi
      }
      description {
        en
        et
        fi
      }
      copywriter {
        en
        et
        fi
      }
      slug {
        en {
          current
        }
        fi {
          current
        }
        et {
          current
        }
      }
    }
    allSanityPost {
      nodes {
        title {
          en
          et
          fi
        }
        slug {
          en {
            current
          }
          fi {
            current
          }
          et {
            current
          }
        }
        copywriter {
          en
          et
          fi
        }
        description {
          en
          et
          fi
        }
        image {
          altText {
            en
            et
            fi
          }
          asset {
            gatsbyImageData
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

function getTimeFormat(time, lang) {
  return DateTime.fromISO(time).setLocale(lang).toLocaleString(DateTime.DATE_FULL)
}

const BlogPostTemplate = (props) => {
  const { data, errors, location } = props
  const post = data && data.post

  const lang = props.pageContext.language
  const _rawBody = post._rawBody[lang] ? post._rawBody[lang] : post._rawBody['fi']
  const mainImage = post?.image?.asset?.localFile?.childImageSharp?.gatsbyImageData || post?.image?.asset?.gatsbyImageData
  const mainImageAlt = post?.image?.altText ? post?.image.altText[lang] : ''
  const title = post.title[lang] ? post.title[lang] : post.title['fi']
  const _rawTags = post._rawTags
  const [posts] = React.useState(() =>
    randomFromArray({
      array: data?.allSanityPost?.nodes ? data.allSanityPost.nodes : [],
      amountOfItemsToReturn: 3,
    })
  )
  const tags = post.tags[lang] ? post.tags[lang] : post.tags['fi']
  const keywords = tags.map((tag) => tag.label).join(', ')
  const metas = [
    {
      property: 'og:type',
      content: 'article',
    },
    {
      property: 'keywords',
      content: keywords
    },
    {
      property: 'robots',
      content: 'index, follow'
    }
  ]


  const blogPosts = posts.map((blogPost, index) => {
    const altText = blogPost?.image?.altText ? blogPost?.image?.altText[lang] : 'Extrapalvelu'
    const link = getLocalizedLink('blog/' + blogPost.slug[lang].current, lang)
    return (
      <Link className="col-xs-12" rel="norewrite" href={link} title={blogPost.title} key={index} to={link}>
        <GatsbyImageSVGFallback image={blogPost?.image} alt={altText} imgStyle={{ width: '120px', height: '120px', objectFit: 'cover' }} />
        <div className="col-xs-12">
          <span className="yarpp-thumbnail-title">{blogPost?.title[lang]}</span>
          <div className="yarpp-post-author">{blogPost.copywriter[lang]}</div>
        </div>
      </Link>
    )
  })
  const similarPosts = lang === 'fi' ? 'Muut blogit' : 'Other blogs'
  const description = post.description
  return (
    <>
      {errors && <GraphQLErrorList errors={errors} />}

      {post && (
        <>
          <Seo title={post.title} language={lang} location={location} description={description} metas={metas} />
          <main>
            <div className="archive-block">
              <div className="container container-sm">
                <div className="row">
                  <div className="col-sm-8">
                    <div className="post-block">
                      <h1 className="title-post">{title}</h1>
                      <div className="d-flex single-post-category">
                        <div className="author-box">
                          <div className="team">{post.copywriter[lang]}</div>
                          <div className="date">{getTimeFormat(post._updatedAt, lang) || getTimeFormat(post._updatedAt, lang)}</div>
                        </div>
                      </div>
                      <div className="img-holder">{mainImage && <GatsbyImage alt={mainImageAlt ? mainImageAlt : ''} image={mainImage} />}</div>
                      <div className="text-content">{_rawBody && <PortableText blocks={_rawBody} />}</div>
                    </div>
                  </div>

                  <div className="col-sm-4 sidebar">
                    <hr />
                    <div className="widget-box">
                      <div className="yarpp-related-widget">
                        <h3>{similarPosts}</h3>
                        <div className="yarpp-thumbnails-horizontal">{blogPosts}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  )
}

export default BlogPostTemplate
