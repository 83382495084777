import {isFuture} from 'date-fns'

export function cn(...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return []
  return data.edges.map((edge) => edge)
}

export function filterOutDocsWithoutSlugs({slug}) {
  return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture({publishedAt}) {
  return !isFuture(publishedAt)
}

export function getBlogUrl(publishedAt, slug) {
  return `/blog/${slug.current || slug}/`
}

export function buildImageObj(source = {asset: {}}) {
  const imageObj = {
    asset: {_ref: source.asset._ref || source.asset._id},
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function toPlainText(blocks) {
  if (!blocks) {
    return ''
  }
  return blocks
    .map((block) => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map((child) => child.text).join('')
    })
    .join('\n\n')
}

export function toExcerpt(blocks, count) {
  let blockText = toPlainText(blocks)
  return trimWords(blockText, count)
}

export function trimWords(str, count) {
  if (!str) {
    return ''
  }
  let wordLength = str.split(' ').length
  if (wordLength > count) {
    return str.split(' ').splice(0, count).join(' ') + '...'
  } else {
    return str
  }
}

export const getLocalizedLink = (link, language) => {
  if (language === 'fi') {
    return `/${link}`
  } else {
    return `/${language}/${link}`
  }
}
